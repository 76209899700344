import Link from 'next/link';

import style from '../styles/components/highlight-item.module.scss'

const HighlightItem = ({title, short_desc, link, image}) => {
  return (
    <div className={style.highlight_item}>
      <div className={style.info_block}>
        <div className={style.content_type}>
          <span className={style.star}><img src="/images/share/icon-content-type-star.svg" alt="content highlight" /></span>
          <span className={`${style.text} grey-1`}>Highlight</span>
          <span className={style.star}><img src="/images/share/icon-content-type-star.svg" alt="content highlight" /></span>
        </div>
        <h2 className={`${style.content_title} grey-1`}>{title}</h2>
        <p className={`${style.content_desc} grey-dark-2`}>{short_desc}</p>
        <div className={`${style.content_link}`}>
          <Link href={link}><a className={`link link-btn-default`}>Explore</a></Link>
        </div>
      </div>
      <div className={`${style.img_block} bg-contain-c`} style={{'backgroundImage': `url(${image})`}}>
        <Link href={link}><a className="img-link img-block"><img src="/images/space/highlight-thumb-space.png" className="full-w" alt={`${style.content_title}`} /></a></Link>
      </div>
    </div>
  );
}

export default HighlightItem;