import axios from 'axios'
import cn from 'classnames'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import SwiperCore, { Navigation, Pagination } from 'swiper/core'
import { Swiper, SwiperSlide } from 'swiper/react'

import App from '../components/app'
import HighlightItem from '../components/HighlightItem'
import MayaBgContainer from '../components/MayaBgContainer'

import style from '../styles/pages/landing.module.scss'

import { getImageUrlApi, getUploadImageBase64 } from '../helpers/utils'

SwiperCore.use([Pagination,Navigation])

function Landing({ setting, landing }) {
  const router = useRouter()
  
  const [settingContent, setSettingContent] = useState(setting)
  const [landingContent, setLandingContent] = useState(landing)
  const [contentComplete, setContentComplete] = useState(false);
  const [activeFloor, setActiveFloor] = useState(0)
  
  useEffect(() => {
    fetchContent();
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', () => handleScroll)
    }
  }, [router])

  const fetchContent = async () => {
    await setContentComplete(false);

    const resLanding = await axios.get(`${process.env.appUrl}/api/landing/${router.locale}`)
    const landing = (resLanding.data) ? resLanding.data : []

    if(landing.landing_content && landing.landing_content.top_banner_dt) {
      for (const i in landing.landing_content.top_banner_dt) {
        landing.landing_content.top_banner_dt[i] = await getUploadImageBase64(`${process.env.appUrl}`, landing.landing_content.top_banner_dt[i])
      }
    }

    if(landing.landing_content && landing.landing_content.top_banner_mb) {
      for (const i in landing.landing_content.top_banner_mb) {
        landing.landing_content.top_banner_mb[i] = await getUploadImageBase64(`${process.env.appUrl}`, landing.landing_content.top_banner_mb[i])
      }
    }
    
    if(landing.landing_content && landing.landing_content.selected_highlights) {
      landing.landing_content.selected_highlights.thumbnail = await getUploadImageBase64(`${process.env.appUrl}`, landing.landing_content.selected_highlights.thumbnail)
    }

    if(landing.landing_content && landing.landing_content.floors) {
      for (const i in landing.landing_content.floors) {
        landing.landing_content.floors[i].floor_plan_image = await getUploadImageBase64(`${process.env.appUrl}`, landing.landing_content.floors[i].floor_plan_image)
      }
    }

    if(landing.landing_content && landing.landing_content.selected_promotions) {
      for (const i in landing.landing_content.selected_promotions) {
        landing.landing_content.selected_promotions[i].thumbnail = await getUploadImageBase64(`${process.env.appUrl}`, landing.landing_content.selected_promotions[i].thumbnail)
      }
    }
    
    if(landing.landing_content && landing.landing_content.activities) {
      for (const i in landing.landing_content.activities) {
        landing.landing_content.activities[i].thumbnail = await getUploadImageBase64(`${process.env.appUrl}`, landing.landing_content.activities[i].thumbnail)
      }
    }

    if(landing.landing_content && landing.landing_content.news) {
      for (const i in landing.landing_content.news) {
        landing.landing_content.news[i].thumbnail = await getUploadImageBase64(`${process.env.appUrl}`, landing.landing_content.news[i].thumbnail)
      }
    }

    await setLandingContent(landing)
    await setContentComplete(true);
  }

  const handleScroll = () => {
    const footerRect = document.querySelector('footer').getBoundingClientRect()
    const newsActivityContent = document.getElementById('news_activity_content')
    const newsActivityContentRect = (newsActivityContent) ? newsActivityContent.getBoundingClientRect() : null

    if(newsActivityContentRect) {
      const cntStick = document.querySelector('.content-stick')
      
      if((newsActivityContentRect.top - 80) < 0) {
        newsActivityContent.classList.add('landing-info-sticky')
      } else {
        newsActivityContent.classList.remove('landing-info-sticky')
      }

      if((footerRect.top + footerRect.height) - (window.innerHeight + 180) <= 0) {
        newsActivityContent.classList.add('content-stick-at-bottom')
        cntStick.style.bottom = `${-((footerRect.top + footerRect.height) - (window.innerHeight + 180))}px`
      } else {
        cntStick.style.bottom = null
        newsActivityContent.classList.remove('content-stick-at-bottom')
      }
    }
  }

  const handleFloorActive = (index) => {
    setActiveFloor(index);
  }

  return (
    <App setting={settingContent}>
      <section className="banner-container">
        
        <div className="show-dt">
          { (contentComplete && landingContent.landing_content.top_banner_dt.length > 0) &&
            <Swiper 
              spaceBetween={0} 
              slidesPerView={1} 
              loop={true} 
              pagination={{ "clickable": true }} 
              navigation={true}>
              {
                landingContent.landing_content.top_banner_dt.map((item, index) => {
                  return (
                    <SwiperSlide key={`top-banner-dt-${index}`}>
                      <div className="img-responsive bg-cover-c" style={{'backgroundImage': `url(${item})`}}>
                        <img src="/images/space/landing-top-banner-dt-space.png" className="full-w" alt="Landing top banner" />
                      </div>
                    </SwiperSlide>
                  )
                })
              }
            </Swiper>
          }
        </div>

        <div className="show-mb">
          { (contentComplete && landingContent.landing_content.top_banner_dt.length > 0) &&
            <Swiper
              spaceBetween={0}
              slidesPerView={1}>
              {
                landingContent.landing_content.top_banner_mb.map((item, index) => {
                  return (
                    <SwiperSlide key={`top-banner-mb-${index}`}>
                      <div className="img-responsive bg-cover-c" style={{'backgroundImage': `url(${item})`}}>
                        <img src="/images/space/landing-top-banner-mb-space.png" className="full-w" alt="Landing top banner" />
                      </div>
                    </SwiperSlide>
                  )
                })
              }
            </Swiper>
          }
        </div>

      </section>

      <MayaBgContainer minusTop>
        <section className="container highlight-container">
        { (contentComplete && landingContent.landing_content.selected_highlights) &&
          <HighlightItem 
          title={landingContent.landing_content.selected_highlights.name} 
          short_desc={landingContent.landing_content.selected_highlights.short_description} 
          link={`/highlight/${landingContent.landing_content.selected_highlights.slug}`} 
          image={landingContent.landing_content.selected_highlights.thumbnail} />
        }
        </section>
      </MayaBgContainer>

      <section>
        <div className={style.landing_main_cat_menu_container}>
          <Link href={`${landingContent.landing_content.shopping_link}`}>
            <div className={style.landing_main_cat_item}>
              <h3 className={style.main_cat_title}>SHOPPING</h3>
              <div className={`${style.main_cat_image} ${style.main_cat_image_1}`}>
                <img src="/images/landing/cat-menu-img-1.png" className="full-w" alt="SHOPPING" />
              </div>
              <span className={style.main_cat_see_more_btn}><img src="/images/share/icon-gold-plus.svg" className="full-w" alt="SEE MORE" /></span>
            </div>
          </Link>
          <Link href={`${landingContent.landing_content.dining_link}`}>
            <div className={style.landing_main_cat_item}>
              <h3 className={style.main_cat_title}>DINING</h3>
              <div className={`${style.main_cat_image} ${style.main_cat_image_2}`}>
                <img src="/images/landing/cat-menu-img-2.png" className="full-w" alt="SHOPPING" />
              </div>
              <span className={style.main_cat_see_more_btn}><img src="/images/share/icon-gold-plus.svg" className="full-w" alt="SEE MORE" /></span>
            </div>
          </Link>
          <Link href={`${landingContent.landing_content.highlight_link}`}>
            <div className={style.landing_main_cat_item}>
              <h3 className={style.main_cat_title}>HIGHLIGHT</h3>
              <div className={`${style.main_cat_image} ${style.main_cat_image_3}`}>
                <img src="/images/landing/cat-menu-img-3.png" className="full-w" alt="SHOPPING" />
              </div>
              <span className={style.main_cat_see_more_btn}><img src="/images/share/icon-gold-plus.svg" className="full-w" alt="SEE MORE" /></span>
            </div>
          </Link>
        </div>
      </section>

      <MayaBgContainer type="2">
        <section className={`${style.floor_content_container} container`}>
          <div className="wrapper-reg">
            
            <div className="section-title with-star with-space-lg-2 text-center justified-content-center">
              <span className="star"><img src="/images/share/icon-content-type-star.svg" className="full-w" alt="content star" /></span>
              <h4 className="grey-1">FLOOR</h4>
              <span className="star"><img src="/images/share/icon-content-type-star.svg" className="full-w" alt="content star" /></span>
            </div>

            <div className={`${style.floor_selector_container} justified-content-center`}>
              { (contentComplete && landingContent.landing_content.floors.length > 0) &&
                landingContent.landing_content.floors.map((item, index) => {
                  return (<a key={`floor-item-${index}`} className={`${style.floor_item} ${ cn({ [ style.floor_item_active ]: activeFloor === index }) }`} onClick={() => { handleFloorActive(index) }}>{ item.name }</a>)
                })
              }
            </div>
              
            <div className={style.floor_map_content_container}>
              <div className={`${style.floor_map_block} img-block`}>
                { (contentComplete && landingContent.landing_content.floors.length > 0) &&
                  landingContent.landing_content.floors.map((item, index) => {
                    return (
                      <div key={`floor-plan-item-${index}`} className={`img-block bg-contain-c ${ cn({ [ style.floor_map_img_active ]: activeFloor === index }) }`} style={{'backgroundImage': `url(${item.floor_plan_image})`}}>
                        <Link href={`/places/store?floor=${item.floor_id}`}>
                          <a className="img-block"><img src="/images/space/dot.png" alt={`floor ${item.name}`} className={`full-w ${ cn({ [ style.floor_map_img_active ]: activeFloor === index }) }`} /></a>
                        </Link>
                      </div>
                    )
                  })
                }
              </div>

              <div className={`${style.floor_content_list} ${style.floor_content_block}`}>

                { (contentComplete && landingContent.landing_content.floors.length > 0) &&
                  landingContent.landing_content.floors.map((item, index) => {
                    return (
                      <div key={`floor-content-item-${index}`} className={`${style.floor_content_item} ${ cn({ [ style.floor_content_item_active ]: activeFloor === index }) }`}>
                        <h3 className={style.floor_main_title}>{ item.aka }</h3>
                        <h5 className={style.floor_sub_title}>{ item.sub_aka }</h5>
                        <p className={style.floor_desc}>{ item.detail }</p>
                      </div>
                    )
                  })
                }
                
              </div>

            </div>
            
          </div>
        </section>
      </MayaBgContainer>

      <section className={style.promotion_content_container}>

        { (contentComplete && landingContent.landing_content.selected_promotions.length > 1) &&
          landingContent.landing_content.selected_promotions.map((item, index) => {
            return (
              <div key={`promotion-item-${index}`} className={style.promotion_item}>
              <div className="img-block bg-cover-c" style={{'backgroundImage': `url(${item.thumbnail})`}}>
                <div className="show-dt"><a href={`/promotion/${ item.slug }`} className="img-block"><img src="/images/space/landing-promotion-thumb-dt-space.png" alt="Promotion content" className="full-w" /></a></div>
                <div className="show-mb"><a href={`/promotion/${ item.slug }`} className="img-block"><img src="/images/space/landing-promotion-thumb-mb-space.png" alt="Promotion content" className="full-w" /></a></div>
              </div>
              { !item.is_hide &&
                <div className={`${style.promotion_content_block} ${ index % 2 === 0 ? style.promotion_content_block_1 : style.promotion_content_block_2 }`}>
                  <div className={style.promotion_title_block}>
                    <div className="content-type-block">
                      { index % 2 === 0 ?
                        <>
                          <span className="star"><img src="/images/share/icon-white-star.svg" alt="content highlight" /></span>
                          <span className="title white">Store Promotion</span>
                          <span className="star"><img src="/images/share/icon-white-star.svg" alt="content highlight" /></span>
                        </>
                        :
                        <>
                          <span className="star"><img src="/images/share/icon-gold-star.svg" alt="content highligh" /></span>
                          <span className="title button-1">MAYA Promotion</span>
                          <span className="star"><img src="/images/share/icon-gold-star.svg" alt="content highligh" /></span>
                        </>
                      }
                    </div>
                    <div className={`${style.promotion_title} ${index % 2 === 0 ? 'white' : 'button-1'}`}>{ item.name }</div>
                  </div>
                  <div className={style.promotion_desc_block}>
                    <p className={style.promotion_desc}>{ item.short_description }</p>
                    <Link href={`/promotion/${ item.slug }`}><a className={`${style.promotion_link} link ${index % 2 === 0 ? 'link-btn-white' : 'link-btn-default'}`}>Find out more</a></Link>
                  </div>
                </div> 
              }
            </div>
            )
          })
        }
      </section>

      <section className={style.about_us_container}>
        <div className={style.about_img_bg}><img src="/images/bg/component-bg-2.png" className="full-w" alt="About Us" /></div>
        <div className={`${style.about_us_block} container`}>
          <div className={`${style.about_item_1}`}>
            <h3 className={style.about_title}>About Us</h3>
          </div>
          <div className={`${style.about_item_2}`}>
            <p>ที่สุดแห่งไลฟ์สไตล์ช็อปปิ้งเซ็นเตอร์ จุดนัดพบแห่งพลังสร้างสรรค์ของความบันเทิงเหนือจินตนาการ เจ้าของโครงการคือ บริษัท มายา ดีเวลลอปเม้นท์ จำกัด ภายใต้การบริหารงานของคุณสุวัฒน์ ทองร่มโพธิ์</p>
          </div>
          <div className={`${style.about_item_3}`}>
            <Link href="/about-us">
              <a className="btn primary-btn no-underline d-block">Read more</a>
            </Link>
          </div>
        </div>
      </section>

      <MayaBgContainer topDisplay="none">
        <section id="news_activity_content" className={style.news_activity_content_container}>
          <div className={`${style.maya_info_block} content-stick`}>
            <div className={style.maya_info_img_block}><img src="/images/share/maya-info-img.jpg" alt="MAYA Lifestyle Shopping Center" className="full-w" /></div>
            <div className={style.maya_info_container}>
              <div className={style.maya_info_title_block}>
                <div className="content-type-block">
                  <span className="star"><img src="/images/share/icon-white-star.svg" alt="content highlight" /></span>
                  <span className="title white">MAYA Lifestyle Shopping Center</span>
                  <span className="star"><img src="/images/share/icon-white-star.svg" alt="content highlight" /></span>
                </div>
                <h4 className={style.maya_info_sub_title}>Open Hours</h4>
                <h3 className={style.maya_info_title}>10:00 - 22:00</h3>
              </div>
              <div className={style.maya_info_desc_block}>
                <p>55 Moo 5, Huay Kaew Rd. Chang Phuak, Muang Chiang Mai, Chiang Mai 50300 </p>
                <br />
                <p>Tel : +66 (0) 5208 1555</p>
              </div>
            </div>
          </div>
          <div className={`${style.maya_new_activity_container} content-scroll`}>
            <div className={style.new_activity_block}>

              { (contentComplete && landingContent.landing_content.news.length > 0) &&
                landingContent.landing_content.news.map((item, index) => {
                  return (
                  <div key={`news-article-item-${index}`} className="content-card-item">
                    <div className="content-card-img">
                      <Link href={`/news-article/${item.slug}`}>
                        <a className="img-block bg-cover-c" style={{'backgroundImage': `url(${item.thumbnail})`}}>
                          <img src="/images/space/landing-new-activity-thumb-space-space.png" alt={item.name} className="full-w" />
                        </a>
                      </Link>
                    </div>
                    <div className="content-type-block gray">
                      <span className="star"><img src="/images/share/icon-content-type-star.svg" alt="content highlight" /></span>
                      <span className="title button-1">MAYA News &amp; Article</span>
                      <span className="star"><img src="/images/share/icon-content-type-star.svg" alt="content highlight" /></span>
                    </div>
                    <Link href={`/news-article/${item.slug}`}>
                      <a className="content-title head-link button-1">{item.name}</a>
                    </Link>
                    <p className="content-desc">{item.short_description}</p>
                    <div><Link href={`/news-article/${item.slug}`}><a className="content-link link link-btn-default">Find out more</a></Link></div>
                  </div>
                  )
                })
              }

              { (contentComplete && landingContent.landing_content.activities.length > 0) &&
                landingContent.landing_content.activities.map((item, index) => {
                  return (
                    <div key={`activity-item-${index}`} className="content-card-item">
                      <div className="content-card-img">
                        <Link href={`/activities/${item.slug}`}>
                          <a className="img-block bg-cover-c" style={{'backgroundImage': `url(${item.thumbnail})`}}>
                            <img src="/images/space/landing-new-activity-thumb-space-space.png" alt={item.name} className="full-w" />
                          </a>
                        </Link>
                      </div>
                      <div className="content-type-block gray">
                        <span className="star"><img src="/images/share/icon-content-type-star.svg" alt="content highlight" /></span>
                        <span className="title button-1">MAYA Activities</span>
                        <span className="star"><img src="/images/share/icon-content-type-star.svg" alt="content highlight" /></span>
                      </div>
                      <Link href={`/activities/${item.slug}`}>
                        <a className="content-title head-link button-1">{item.name}</a>
                      </Link>
                      <p className="content-desc">{item.short_description}</p>
                      <div><Link href={`/activities/${item.slug}`}><a className="content-link link link-btn-default">Find out more</a></Link></div>
                    </div>
                  )
                })
              }
              
            </div>
          </div>
        </section>
      </MayaBgContainer>

    </App>
  )
}

export async function getServerSideProps({ locale }) {
  const apiSettingRes = await axios.get(`${process.env.api}/site-setting/${locale}`)
  const setting = (apiSettingRes) ? apiSettingRes.data : null;

  const apiLandingRes = await axios.get(`${process.env.api}/landing/${locale}`)
  const landing = (apiLandingRes.data) ? apiLandingRes.data : []

  setting.favicon = await getImageUrlApi(`${process.env.appUrl}`, setting.favicon)
  setting.logo = await getImageUrlApi(`${process.env.appUrl}`, setting.logo)
  setting.soc_image = await getImageUrlApi(`${process.env.appUrl}`, setting.soc_image)

  return { props: { setting, landing } }
}

const mapStateToProps = state => {
  return {}
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Landing)