import style from '../styles/components/maya-bg-container.module.scss'

const MayaBgContainer = ({ children, minusTop, type, topDisplay, bottomDisplay }) => {
  let bg_type;
  if(type == 2) {
    bg_type = style.maya_bg_type_2
  } else {
    bg_type = null;
  }

  return (
    <div className={`${style.maya_bg_container} ${ (minusTop) ? style.with_minus_top : '' } ${bg_type}`}>
      <div className={style.bg_top} style={{'display': topDisplay}}><img src="/images/bg/component-bg-1.png" className="full-w" alt="Section background start" /></div>
      { children }
      <div className={style.bg_bottom} style={{'display': bottomDisplay}}><img src="/images/bg/component-bg-2.png" className="full-w" alt="Section background start" /></div>
    </div>
  );
}
export default MayaBgContainer;